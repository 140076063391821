import React from "react";
import { graphql } from 'gatsby';
import get from 'lodash/get';
import { Helmet } from 'react-helmet';
import Layout from "../components/Layout";
import styled from 'styled-components';
import Img from "gatsby-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import RecommendationTeaser from "../components/recommendation/RecommendationTeaser";

class ArticleTemplate extends React.Component {

  render(props) {
    const article = get(this.props, 'data.contentfulArticle');
    const bgImage = article.images[0].fixed.src;
    const places = article.referencedPlaces;

    return (
      <Layout>
        <Helmet>
	        <title>Best in Brighton Articles: {article.title}</title>
	      </Helmet>
	      <div
	        className="page-hero"
	        style={{
	          backgroundImage: `url(${bgImage})`,
	        }}
	      >
	         <h1>{article.title}</h1>
	      </div>

	      <div className="container container-content">
            <TagWrapper>
                {article.tags[0].title}
            </TagWrapper>
          <div>
              {renderRichText(article.body)}
          </div>

          { places &&
            <ReferencedPlaces>
              <h2>Related recommendations</h2>
              <ul>
                {places.map(place => {
                      return(
                          <li key={place.id}>
                            <RecommendationTeaser node={place}/>
                          </li>
                      );
                  })}
                </ul>
            </ReferencedPlaces>
          }

        </div>
      </Layout>
    ) 
  }
}

export default ArticleTemplate


const TagWrapper = styled.div`
    margin: 10px 0;
    border-top: 1px solid #d8cfcf;
    border-bottom: 1px solid #d8cfcf;
    padding: 10px 0;
    text-transform: uppercase;
    letter-spacing: 0.25em;
    font-size: 12px;
`
const ReferencedPlaces = styled.div`

  ul {
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    
    li {
      padding: 5px;
      flex: 0 0 30%;
    }
  }

  h2 {
    padding: 10px 0;
    border-top: 1px solid #d8cfcf;
    border-bottom: 1px solid #d8cfcf;
  }
`

export const PageQuery = graphql`
  query PageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulArticle(slug: { eq: $slug }) {
        title
        slug
        images {
          fixed(width: 1500, height: 400) {
            ...GatsbyContentfulFixed
          } 
        }
        body {
          raw
        }
        tags {
          title
        }
        referencedPlaces {
          id
          name
          rating
          slug
          description {
            description
          }
          images {
            fixed(width: 300, height: 200) {
              ...GatsbyContentfulFixed
            } 
          }
        }
      }

    }
  `;